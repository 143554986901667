<template>
  <el-card class="chart_box" v-loading="chartLoading">
    <div class="box_top">
      <div class="title">
        {{ $t('home.Dashboard') }}
        <el-dropdown
          ref="dropdownDom"
          trigger="click" 
          placement="bottom-start">
          <div class="selectTypeBox">
            <i :class="`iconfont ${selectedTypeItem.icon}`"></i>
            {{ selectedTypeItem.label }}
            <i class="el-icon-caret-bottom" :class="$refs.dropdownDom && $refs.dropdownDom.visible ? 'active' : ''"></i>
          </div>
          <el-dropdown-menu class="ec-popper" slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in typeOptions"
              :key="index"
              class="popperMenuItem"
              :class="data.type == item.value ? 'active' : ''"
              :icon="`iconfont ${item.icon}`"
              @click.native="data.type = item.value"
            >
              {{ item.label }}
              <i v-if="data.type == item.value" class="el-icon-success"></i>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        class="more"
        @click="() => this.$router.push({ path: '/dashboard/0' })"
      >
        <span>
          {{ $t("memberList.more") }}
          <i class="el-icon-arrow-right" />
        </span>
      </div>
    </div>
    <div class="screen">
      <el-date-picker
        style="width: 100%;max-width: 300px;"
        v-model="data.time"
        size="small"
        type="daterange"
        :start-placeholder="$t('Dashboard.Startfrom')"
        :end-placeholder="$t('Dashboard.Endoftime')"
        :picker-options="pickerData"
      />
      <div class="translateButton">
        <el-tabs class="special_tabs" v-model="data.queryType">
          <el-tab-pane 
            v-for="(item, index) in queryTypeOptions" 
            :key="index" 
            :label="item.label" 
            :name="String(item.value)" 
          />
        </el-tabs>
      </div>
    </div>
    <div :id="mainChartName" class="mainEcharts" />
  </el-card>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { orderChart } from "@/api/chart";
import { pickerOptions } from "@/utils/utils";

export default {
  name: "indexChart",
  data() {
    return {
      myChart: null,
      mainChartName: 'mainChartName',
      chartList: [],
      total: 0,
      data: { 
        time: [dayjs().startOf("year"), dayjs()],
        type: 0,
        queryType: 0
      },
      typeOptions: [{
        label: this.$t('order.Company'),
        icon: "icon-gongsi2",
        value: 0
      }, {
        label: this.$t('EditMine.Country'),
        icon: "icon-country",
        value: 1
      }],
      queryTypeOptions: [{
        label: this.$t('order.Amount'),
        value: 0
      }, {
        label: this.$t('createShipping.Quantity'),
        value: 1
      }],
      chartLoading: false,
    };
  },
  computed: {
    ...mapGetters(["newOrderUpdate", "chartPieColors", "minuteCountry"]),
    pickerData() {
      return pickerOptions();
    },
    selectedTypeItem() {
      return this.typeOptions.find(item => item.value == this.data.type);
    }
  },
  watch: {
    'data.time'(val) {
      if(val) {
        this.onUpdate();
      }
    },
    'data.type'() {
      this.onUpdate();
    },
    'data.queryType'() {
      this.onUpdate();
    },
    chartPieColors: {
      handler() {
        this.$nextTick(() => {
          this.myChart && this.myChart.dispose();
          this.setEcharts();
        })
      },
      deep: true
    },
    newOrderUpdate() {
      this.onUpdate();
    }
  },
  activated() {
    this.setEcharts();
  },
  mounted() {
    this.onUpdate();
  },
  methods: {
    onUpdate() {
      this.chartLoading = true;
      const { time } = this.data;
      let startTime = time ? dayjs(time[0]).startOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
      let endTime = time ? dayjs(time[1]).endOf('date').format("YYYY-MM-DD HH:mm:ss") : "";
      this.onGetChart({ startTime, endTime });
    },
    setEcharts() {
      this.$nextTick(() => {
        var chartDom = document.getElementById(this.mainChartName);
        if(!chartDom) {
          this.chartLoading = false;
          return;
        }
        this.myChart = this.$echartsInit(chartDom);
        let option = {
          title: [{
            text: this.total || 0,
            textStyle: {
              color: "#122545",
              fontSize: 24,
              fontWeight: "bold"
            },
            top: "40%",
            left: "48.5%",
            textAlign: "center",
          }, {
            text: this.data.queryType == 1 ? this.$tc("home.OrdersTotal", this.$isOrderTitle()) : this.$tc("home.OrderAmount", this.$isOrderTitle()),
            textStyle: {
              color: "#9291A5",
              fontSize: 12,
              fontWeight: "400"
            },
            top: "50%",
            left: "48.5%",
            textAlign: "center",
          }],
          tooltip: {
            trigger: "item",
            confine: true,
            formatter: function (params) {
              var htmlStr = `
                <div class="echartsTooltipPieBox">
                  <div class="content">
                    <div class="label">${params.name}</div>
                    <div class="value">${params.value}</div>
                  </div>
                </div>
              `;
              return htmlStr;
            },
          },
          legend: {
            // x: 'center',
            bottom: "-20",
            type: "scroll",
            textStyle: {
              fontSize: 12,
              color: "#2E3F4FA8",
            },
            padding: [32, 0, 32, 0],
            itemWidth: 12,
            itemHeight: 12
          },
          series: [
            {
              type: "pie",
              radius: ["40%", "70%"],
              bottom: '5%',
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 4,
              },
              label: {
                show: false,
                position: "inside",
                formatter: `{d}%`,
                color: "#ffffff",
                fontSize: 8 //字体大小
              },
              labelLine: {
                show: false,
              },
              data: this.chartList?.length
                ? this.chartList
                : [
                    {
                      value: 0,
                      name: "",
                    },
                  ],
            },
          ],
        };
        this.myChart.clear();
        this.myChart.setOption(option);
        window.addEventListener("resize", () => {
          if(document.getElementById(this.mainChartName)) {
            this.myChart.resize();
          }
        });
      });
    },
    onGetChart(parameter) {
      orderChart({
        ...parameter,
        type: this.data.type,
        queryType: this.data.queryType
      })
        .then((data) => {
          let total = 0;
          let arr = data.map((item) => {
            const { orderCount, aliasName, companyId } = item;
            total += orderCount;
            return { value: orderCount, name: aliasName || this.$isFindData(this.minuteCountry, companyId, 'iso3', 'countryName') };
          }).sort((a, b) =>  b.value - a.value);
          this.chartList = arr;
          this.total = this.data.queryType == 0 ? this.formatAmount(total) : total;
          this.setEcharts();
        })
        .finally(() => (this.chartLoading = false));
    },
    formatAmount(amount) {
      const formattedAmount = amount.toFixed(2); // 将金额保留两位小数
      if (parseInt(formattedAmount) >= 1000) {
        if (parseInt(formattedAmount) >= 1000000) {
          return `${(parseInt(formattedAmount) / 1000000).toFixed(2)}M`; // 金额超过1000时添加"K"作为单位
        }
        return `${(parseInt(formattedAmount) / 1000).toFixed(2)}K`; // 金额超过1000时添加"K"作为单位
      } else  {
        return formattedAmount; // 金额不超过1000时直接返回原金额
      }
    }
  },
};
</script>
<style lang="less" scoped>
.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  margin-top: 7px;
  .more {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    color: #2e3f4f;
    &:hover {
      color: #67A691;
    }
  }
  .title {
    /deep/ .el-dropdown {
      margin-left: 10px;
    }
    .selectTypeBox {
      color: #004C97;
      font-weight: 600;
      display: flex;
      align-items: center;
      cursor: pointer;
      .iconfont {
        font-weight: 500;
        position: relative;
        top: 1px;
        margin-right: 2px;
      }
      .el-icon-caret-bottom {
        margin-left: 5px;
        color: #757D8A;
        transition: all .3s;
        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.chart_box {
  width: 100%;
  height: 412px;
  margin-bottom: 12px;
  .screen {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
    /deep/ .special_tabs {
      background: #F7F9FC;
      .el-tabs__active-bar {
        background: #E0E4EA;
      }
      .el-tabs__item {
        color: #637381;
        &.is-active {
          color: #122545 !important;
        }
      }
    }
  }
  /deep/ .el-card__body {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.mainEcharts {
  width: 100%;
  height: calc(100% - 105px);
  position: absolute;
  left: 0;
  padding: 0 16px;
}
</style>
