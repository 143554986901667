<template>
  <el-dialog
    :visible.sync="isCompanySelect"
    :title="$t('people.Choose to join/create')"
    width="358px"
    :before-close="companySelectClose"
    :destroy-on-close="true"
    :modal-append-to-body="false"
    v-move-outside
  >
    <div class="companySelectBox">
      <div class="item" :class="companySelectType == 1 ? 'active' : ''" @click="companySelectType = 1">
        <img src="../../assets/image/addCompanyIcon.svg" />
        <p>{{ $t('people.Join an existing company') }}</p>
      </div>
      <div class="item" :class="companySelectType == 2 ? 'active' : ''" @click="companySelectType = 2">
        <img src="../../assets/image/createCompanyIcon.svg" alt="">
        <p>{{ $t('people.Create Company') }}</p>
      </div>
    </div>
    <div slot="footer">
      <el-button
        class="cancel_button"
        @click="companySelectClose"
        size="small"
        >{{ $t('Forwarder.Cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="companySelectSubmit"
        :disabled="!companySelectType"
        size="small"
        >{{ $t('home.Confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "companySelectDialog",
  data() {
    return {
      isCompanySelect: false,
      companySelectType: null
    }
  },
  methods: {
    open() {
      this.isCompanySelect = true;
    },
    companySelectSubmit() {
      if(this.companySelectType == 1) {
        this.$router.push(`/companyProfile?type=join`)
      }
      if(this.companySelectType == 2) {
        this.$router.push(`/companyProfile?companyName=yourownpage&type=add`)
      }
      this.isCompanySelect = false;
    },
    companySelectClose() {
      this.isCompanySelect = false;
    }
  }
}
</script>

<style lang="less" scoped>
.companySelectBox {
  display: flex;
  gap: 10px;
  .item {
    width: 158px;
    height: 132px;
    border-radius: 4px;
    border: 1px solid #C7DDD8;
    background: #F7F9FC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    &.active {
      border: 1px solid #076F49;
      p {
        font-weight: 600;
        color: #122545;
      }
    }
    img {
      width: 80px;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      color: #637381;
      position: absolute;
      bottom: 15px;
    }
  }
}
</style>