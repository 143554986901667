<template>
  <div class="userItem" :class="item.top ? 'active' : ''">
    <div 
      class="avatarList" 
      v-if="item.user.memberList && item.user.memberList.length">
      <img 
        v-for="(row, index) in item.user.memberList.filter((row, index) => index < 4)" 
        :src="row.avatarFileUrl" 
        :key="index"
      />
    </div>
    <member v-else-if="!item.keyEvent" :isBadge="item.unReadNum" :info="item.user" :size="48" />
    <i v-else class="iconfont avatar" :class="iconComputed"></i>
    <div class="content" @click="handleEvent">
      <div class="topBox">
        <div class="name" v-showTootip v-if="type === 'message'">
          {{ item.user && item.user.nickName }}
        </div>
        <div class="name" v-showTootip v-else-if="type === 'event'">
          <template v-if="!item.keyEvent">
            {{ item.user.nickName }}
            {{ $t("InformationCenter.posted") }}
            <span> {{ item.subject }}</span>
            {{ $t("InformationCenter.in") }}
            {{ item.ci || item.pi }}
          </template>
          <template v-else>
            {{ systemName }}
          </template>
        </div>
        <div class="name" v-showTootip v-else>
          {{ item.user && item.user.nickName }}
          {{ $t("InformationCenter.update") }}
          {{ $t("InformationCenter.Todo") }}
          <span>{{ item.content }}</span>
          {{ $t("InformationCenter.in") }}
          {{ item.ci }}
        </div>
        <div class="active" v-if="!item.read && type != 'message'"></div>
      </div>
      <div class="bottomBox">
        <div class="message" v-showTootip>{{ item.lastMessage || item.content || (distinguish(item) == 1 ? $t("InformationCenter.notified") : $t("InformationCenter.assignedto")+$t("InformationCenter.you")) }}</div>
        <div class="time">{{ isToday(item.lastMessageTimeStr || item.updateTime || item.createTime) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "message"
    },
    item: {
      type: Object,
      default: {}
    },
    businessUserId: {
      type: Number | String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    //判断To-do是分配给我，还是完成后通知我
    distinguish() {
      return function(data) {
        const { assignMe, notifyMe } = data;
        let index = 1;
        if (assignMe && notifyMe) {
          index = 3;
        } else if (assignMe) {
          index = 2;
        } else if (notifyMe) {
          index = 1;
        }
        return index;
      }
    },
    iconComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return "icon-dingdan1";
        } else if(content.type == 2) {
          return "icon-yundan";
        } else if(content.type == 3) {
          return "icon-daka";
        } else {
          return "icon-shuaxin";
        }
      }
    },
    behaviorComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 2) {
          if(content.status == 1) {
            return this.$t("order.Created");
          } else {
            return this.$t("order.Changed");
          }
        } else if(content.type == 3) {
          return this.$t("order.Clocked");
        } else  {
          return this.$t("order.Changed");
        }
      }
    },
    titleTypeComputed() {
      let content = this.item.contentJson;
      if(content) {
        if(content.type == 1) {
          return this.$tc("order.OrderInfo", this.$isOrderTitle());
        } else if(content.type == 2) {
          return this.$t("order.shipInfo");
        } else if(content.type == 3) {
          return this.$tc("order.orderHistory", this.$isOrderTitle());
        } else {
          return this.$tc("order.Order node", this.$isOrderTitle());
        }
      }
    },
    systemName() {
      let content = this.item.contentJson;
      if(content) {
        return `${content.type != 4 ? this.item.createPerson.nickName : this.$t('memberList.manage')} ${this.behaviorComputed} ${this.titleTypeComputed}`
      }
    },
  },
  methods: {
    isToday(time) {
      let todayDate = new Date().setHours(0,0,0,0);
      let paramsDate = new Date(time).setHours(0,0,0,0);
      if(todayDate === paramsDate) {
        return time.split(" ")[1]
      } else {
        return time.split(" ")[0]
      }
    },
    handleEvent(e) {
      this.$emit("click", e);
    }
  },
}
</script>

<style lang="less" scoped>
.userItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #F7F9FC;
  padding: 10px;
  transition: all .3s;
  .avatarList {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    background: #E3E9F1;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: #076F49;
    font-size: 20px;
  }
  .content {
    flex: 1;
    width: 0;
    margin-left: 10px;
    .topBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        color: #122545;
        font-weight: 500;
        font-size: 14px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 23px;
        span {
          color: #409eff;
        }
      }
      .badge {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: #DC3545;
        // font-size: 12px;
        position: relative;
        top: -2px;
        margin-left: 10px;
        span {
          font-size: 12px;
          &.scale {
            transform: scale(.8);
          }
        }
      }
      .active {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #DC3545;
        position: relative;
        top: -2px;
        margin-left: 10px;
      }
    }
    .bottomBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      .message {
        width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #757D8A;
      }
      .time {
        width: 90px;
        font-size: 12px;
        color: #A3B0C6;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
  &:hover {
    background: #F7F9FC;
  }
  &.active {
    background: #F7F9FC;
  }
}
</style>