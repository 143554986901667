<template>
  <div class="routerBox">
    <div class="home">
      <div class="home_left_box">
        <el-card class="customer_box" v-loading="loading_customer">
          <div class="box_top">
            <div class="title">
              {{
                companyCurrentType != "0"
                  ? $t("home.customerTitle")
                  : $t("home.customerTitle1")
              }}
            </div>
            <div class="more">
              <el-button
                type="primary"
                size="small"
                v-if="company"
                icon="el-icon-plus"
                @click="addCustomerShow1 = true"
                >{{ $t("home.new") }}</el-button
              >
              <span @click="toCustomer()">
                {{ $t("memberList.more") }}
                <i class="el-icon-arrow-right" />
              </span>
            </div>
          </div>
          <div class="emptyBox">
            <el-empty
              :image="$emptyImage"
              :description="company ? $t('overallSituation.noData') : $t('home.Only by joining/creating a company can you view it')"
              :image-size="90"
              v-if="!customerList.length"
            />
            <el-button
              type="primary"
              size="small"
              v-if="!company"
              icon="el-icon-plus"
              @click="$refs.companySelectDialog.open()"
              >{{ $t("home.Join/Create Company") }}</el-button
            >
          </div>
          <div class="customer_box_list">
            <div
              v-for="(item, index) in customerList"
              :key="index"
              class="item"
              :class="item.check ? 'active' : ''"
              @click="linkTo('/customer', item)"
            >
              <div v-if="item.temp == 1" class="custom">{{ $t('custom') }}</div>
              <div
                :class="`item_name ${
                  item.check ? 'selected' : ''
                }`"
                @click.stop
              > 
                <div>
                  <i class="iconfont icon-yishoucang" v-if="item.favorite"></i>
                </div>
                <el-popover
                  ref="popover_customer"
                  class="el-pop"
                  placement="right-start"
                  trigger="click"
                  @hide="item.check = false"
                  popper-class="ec-popper"
                >
                  <div class="popperMenuItem" @click="setTop(item, index)">
                    <i class="iconfont icon-shoucang" />
                    <span>{{ customerFollowed(item) }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    v-if="manageCompany"
                    @click="invite(item)"
                  >
                    <i class="iconfont icon-yaoqingyuangong"></i>
                    <span>{{ $t("home.inviteEmployees") }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    @click="hideConnection(item, index)"
                  >
                    <i class="iconfont icon-yincang2"></i>
                    <span>{{ item.hidden ? $t('home.Cancel')+$t('home.Hide') : $t('home.Hide') }}</span>
                  </div>
                  <!-- <div -->
                    <!-- v-if="isSetGroup" -->
                    <!-- class="popperMenuItem" -->
                    <!-- @click="groupManagementId = `${item.companyCustomerId}|${item.companyId}`" -->
                  <!-- > -->
                    <!-- <i class="iconfont icon-zuqunshezhi"></i> -->
                    <!-- <span>{{ $t("home.set group") }}</span> -->
                  <!-- </div> -->
                  <div
                    class="popperMenuItem"
                    v-if="item.temp == 1 && manageCompany"
                    @click="accountTransfer(item)"
                  >
                    <i class="iconfont icon-transfer"></i>
                    <span>{{ $t("home.Account transfer") }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    v-if="item.temp == 1 && manageCompany"
                    @click="editCustomer(item)"
                  >
                    <i class="iconfont icon-Vector"></i>
                    <span>{{ $t("home.Edit") }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    @click="removeConnection(item, index)"
                  >
                    <i class="iconfont icon-shanchu"></i>
                    <span>{{ $t("home.Remove") }}</span>
                  </div>
                  <i
                    class="iconfont icon-Ellipsis downEllipsis"
                    slot="reference"
                    @click.stop="activePopover(customerList, item, 'popover_customer')"
                  />
                </el-popover>
              </div>
              <div class="customer_li_src">
                <el-image :src="item.companyLogoUrl" fit="cover"></el-image>
              </div>
              <div class="customerName">{{ item.aliasName || item.name }}</div>
            </div>
          </div>
        </el-card>
        <el-card class="order_box" v-loading="loading_order">
          <div class="box_top">
            <div class="title">
              {{ $isOrderTitle() }}
            </div>
            <div class="more">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                v-permission="'create:order:btn'"
                v-if="companyCurrentType == '1'"
                @click="createOrder()"
              >{{ $t("home.new") }}</el-button>
              <span @click="toOrder()">
                {{ $t("memberList.more") }}
                <i class="el-icon-arrow-right" />
              </span>
            </div>
          </div>
          <ul class="order_box_list" :class="!orderList.length ? 'flexAlign' : ''">
            <el-empty
              :image="$emptyImage"
              :image-size="90"
              :description="company ? $t('overallSituation.noData') : $t('home.Only by joining/creating a company can you view it')"
              v-if="!orderList.length"
            />
            <el-button
              type="primary"
              size="small"
              v-if="!company"
              icon="el-icon-plus"
              @click="$refs.companySelectDialog.open()"
              >{{ $t("home.Join/Create Company") }}</el-button
            >
            <li
              v-for="(item, index) in orderList"
              :key="index"
              class="order_item"
              :class="item.check ? 'active' : ''"
              @click="linkToOrder('/order/orderDetails', item)"
            >
              <div class="image_status" :style="`color: ${orderStatusColors[item.progress]}`">
                <i :class="`iconfont ${item.icon}`"></i>
              </div>
              <div class="item_top">
                <div class="item_orderid">
                  <i class="iconfont icon-yishoucang" v-if="item.favorite"></i>
                  {{ item.pi && !item.ci ? `${$isOrderPITitle()}：${item.pi}` : `CI：${ item.ci }` }}
                </div>
                <div class="itemPrice">
                  {{ item.currency }} {{ item.amount }} |
                  {{ item.customerAliasName }}
                </div>
              </div>
              <div class="content">
                <div class="state">
                  <el-tooltip
                    effect="light"
                    placement="top"
                  >
                    <div class="progressContent" slot="content">
                      <div 
                        class="item" 
                        v-for="(row, index) in item.progressList.filter((dd, index) => index <= item.progressList.findIndex(row => row.progress == item.progress))" 
                        :key="index">
                        <div class="label">
                          <i class="el-icon-check"></i>
                          {{ computedNodeList(row.progress).name }}
                        </div>
                        <div class="time">{{ progressTime(row, item) }}</div>
                      </div>
                    </div>
                    <div
                      class="stateIconBox" 
                      :style="{
                        left: `calc(${item.progressValue}% - 10px)`,
                        background: orderStatusColors[item.progressList.findIndex(row => row.progress == item.progress)]
                      }">
                      <i :class="`state_icon iconfont ${item.icon}`"></i>
                    </div>
                  </el-tooltip>
                  <el-progress :percentage="item.progressValue" :color="orderStatusColors[item.progressList.findIndex(row => row.progress == item.progress)]" :stroke-width="8" :show-text="false"></el-progress>
                </div>
                <div class="item_bottom">
                  <p>{{ item.createTime }}</p>
                </div>
              </div>
              <div class="icon_popover">
                <div
                  class="reference"
                  v-if="item.createPerson == businessUserId"
                >
                  <img
                    src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/user_icon.png"
                  />
                </div>
                <el-popover
                  ref="popover_order"
                  class="el-pop"
                  placement="right-start"
                  trigger="click"
                  @hide="item.check = false"
                  popper-class="ec-popper"
                >
                  <div
                    class="popperMenuItem"
                    v-if="item.favorite"
                    @click="unFollowOrder(item, index)"
                  >
                    <i class="iconfont icon-shoucang" />
                    <span>{{ $t("home.Unfollow") }}</span>
                  </div>
                  <div class="popperMenuItem" v-else @click="followOrder(item, index)">
                    <i class="iconfont icon-shoucang" />
                    <span>{{ $t("home.Follow") }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    v-if="companyCurrentType == '1' && item.createPerson == businessUserId"
                    @click="editOrder(item)"
                    :style="
                      companyCurrentType == '1' ? '' : 'color: #ccc'
                    "
                  >
                    <i class="iconfont icon-Vector"></i>
                    <span>{{ $t("home.Edit") }}</span>
                  </div>
                  <div
                    class="popperMenuItem"
                    v-if="item.createPerson == businessUserId"
                    @click="deleteOrder(item)"
                  >
                    <i class="iconfont icon-shanchu"></i>
                    <span>{{ $t("home.Delete") }}</span>
                  </div>
                  <i
                    class="iconfont icon-Ellipsis downEllipsis"
                    slot="reference"
                    @click.stop="activePopover(orderList, item, 'popover_order')"
                  />
                </el-popover>
              </div>
            </li>
          </ul>
        </el-card>
      </div>
      <div class="home_right_box">
        <indexChart />
        <information />
      </div>
    </div>
    <createCustomer
      :addCustomerShow.sync="addCustomerShow1"
      :invitationShow.sync="invitationShow"
      @handleClose="handleCustomerClose"
      @openInvitationDialog="(val) => (invitationShow = val)"
    />
    <createOrder
      ref="createOrderDialog"
      :editContent="editContent"
      :customerInfo="null"
      :action="action"
      @afterCreateOrder="afterCreateOrder"
    />
    <assignApproveList
      :approveListShow.sync="approveListShow1"
      :customerCompanyId="customerCompanyId"
      :allConnection="allConnection"
      @handleClose="handleApproveClose"
      :func="func"
    />
    <groupManagement
      v-if="groupManagementId"
      :customerCompanyId.sync="groupManagementId"
      @close="groupManagementId = ''"
    ></groupManagement>
    <deleteOrder
      :deleteOrderShow.sync="deleteOrderShow"
      @handleClose="deleteOrderShow = false"
      :deleteInfo="deleteInfo"
      @afterDeleteOrder="afterDeleteOrder"
    />
    <el-dialog
      :title="$t('home.Account transfer to')"
      :visible.sync="transferShow"
      @close="transferShow = false"
      width="358px"
      v-move-outside
    >
      <div class="invitation_box">
        <div class="form_item">
          <el-input
            v-model="transferForm.username"
            :placeholder="$t('home.CustomerName')"
            clearable
            size="small"
          />
        </div>
        <div class="form_item">
          <el-input
            v-model="transferForm.email"
            :placeholder="$t('home.Email')"
            clearable
            size="small"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button 
          class="cancel_button" 
          size="small" 
          @click="transferCopy"
        >{{ $t('home.copylink') }}</el-button>
        <el-button
          type="primary"
          size="small"
          @click="transferSubmit"
          :loading="transferLoading"
        >
          {{$t('InformationCenter.Send')}}
        </el-button>
      </div>
    </el-dialog>
    <createCustomCustomer ref="createCustomCustomer" />
    <companySelectDialog ref="companySelectDialog" />
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

import indexChart from "./indexChart.vue";
import information from "./information.vue";
import createCustomer from "@/components/createCustomer/createCustomer";
import createOrder from "@/components/createOrder/createOrder";
import assignApproveList from "@/components/assignApproveList/assignApproveList";
import headerSearch from "@/components/HeaderSearch/HeaderSearch";
import Steps from "@/components/orderProgress/orderProgress";
import companySelectDialog from "@/components/companySelectDialog";
import deleteOrder from "@/components/deleteOrder/deleteOrder";
import createCustomCustomer from "@/components/createCustomer/createCustomCustomer.vue";

import { follow, unfollow, getOrderList } from "@/api/order";
import {
  getUserCustomList,
  collectCustomer,
  unCollectCustomer,
  showCustomer,
  deleteCustomerConnection,
  sendTransferAccountEmail,
  getGenerateTransferUrl
} from "@/api/customer";

export default {
  name: "Index",
  components: {
    createCustomer,
    createOrder,
    headerSearch,
    assignApproveList,
    Steps,
    information,
    indexChart,
    companySelectDialog,
    deleteOrder,
    createCustomCustomer
  },
  //home的子组件中使用inject后可以任意地方调用this.reload()来刷新home下的路由
  inject: ["reload"],
  data() {
    return {
      dayjs: dayjs,
      transferShow: false,
      transferForm: {
        username: "",
        email: ""
      },
      transferLoading: false,
      customAccountId: null,
      groupManagementId: "",
      customerList: [],
      orderList: [],
      deleteInfo: null,
      deleteOrderShow: false,
      isCompanySelect: false,
      invitationShow: false,
      addCustomerShow1: false,
      approveListShow1: false,
      customerCompanyId: "", //邀请自己公司谁可以看公司列表时候用
      allConnection: [], //邀请自己公司员工时判断哪些已经被邀请
      func: "Invite",
      editContent: null,
      action: "",
      loading_order: false,
      loading_customer: false,
      stateList: [
        "PRODUCING",
        "DELIVERY_LOCAL",
        "SEND_PORT",
        "DELIVERY_TRANS",
        "RECEIVE_PORT",
        "DELIVERY_DEST",
        "DESTINATION",
      ]
    };
  },
  computed: {
    ...mapGetters([
      "manageCompany",
      "companyCurrentType",
      "businessUserId",
      "company",
      "applyType",
      "orderStatusColors",
      "orderProgress",
      "newOrderUpdate",
      "readApply",
      "allNodeList"
    ]),
    computedNodeList() {
      return function (type) {
        return this.allNodeList.find(item => item.type == type);
      }
    },
    isSetGroup() {
      return (
        this?.companyCurrentType == "1"
      );
    },
    customerFollowed() {
      return (item) => {
        return item.favorite
          ? this.$t("home.Unfollow")
          : this.$t("home.Follow");
      };
    },
    progressTime() {
      return function (row, item) {
        return item.progressList.find(dd => dd.progress == row.progress).date
      }
    },
  },
  watch: {
    applyType(val) {
      this.getList({
        currentPage: 1,
        pageSize: 5
      });
    },
    $route(to, from) {
      this.path = to.path;
    },
    companyCurrentType: {
      handler() {
        this.getList({
          currentPage: 1,
          pageSize: 5
        });
      },
      immediate: true,
    },
    newOrderUpdate() {
      this.getOrders();
    },
    readApply() {
      this.getList();
    },
    allNodeList: {
      handler() {
        this.getOrders();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //获取客户中Forwarder的表
    getList() {
      this.loading_customer = true;
      getUserCustomList({
        currentPage: 1,
        pageSize: 5,
        hidden: 1,
        companyType: this.companyCurrentType == '1' ? '0' : this.companyCurrentType == '0' ? '1' : this.companyCurrentType == '2' ? '1' : '0'
      }).then((data) => {
        this.customerList = data.list.map(item => ({
          ...item,
          check: false
        }));
      }).finally(() => this.loading_customer = false);
    },
    // 自定义账号编辑
    editCustomer(item) {
      this.$refs.createCustomCustomer.open(item, 'edit');
    },
    // 账号转移
    accountTransfer(row) {
      this.transferShow = true;
      this.customAccountId = row.companyCustomerId;
    },
    transferCopy() {
      getGenerateTransferUrl(this.customAccountId).then((data) => {
        const theClipboard = navigator.clipboard;
        theClipboard.writeText(data).then(() => {
          this.$message.success(this.$t('home.Copysucceeded'));
        });
      })
    },
    transferSubmit() {
      this.transferLoading = true;
      if (this.transferForm?.email && this.transferForm?.username) {
        sendTransferAccountEmail({
          ...this.transferForm,
          companyCustomerId: this.customAccountId
        }).then(() => {
          this.$message.success(this.$t('home.Sent successfully'));
          this.transferShow = false;
          this.transferForm = {
            username: '',
            email: ''
          };
        }).finally(() => {
          this.transferLoading = false;
        });
      } else {
        this.$message.error(this.$t('people.Please input name')+' '+this.$t('or')+' '+this.$t('people.Please input email'));
        this.transferLoading = false;
      }
    },
    //获取订单
    getOrders() {
      this.loading_order = true;
      getOrderList({
        currentPage: 1,
        pageSize: 5,
        sortField: "collect"
      })
        .then((data) => {
          if (!data) return;
          this.orderList = data.list.map(item => ({
            ...item,
            check: false,
            icon: this.allNodeList.find(row => row.type == item.progress).icon,
            progressValue: Math.floor(1/(item.progressList.length)*(item.progressList.findIndex(row => row.progress == item.progress)+1)*100)
          }))
        })
        .finally(() => (this.loading_order = false));
    },
    //初始化重构界面客户清单，按顺序
    initialList(res_data, key) {
      this[key].splice(0, this[key].length);
      for (var i = 0; i < res_data.operationDataList.length; i++) {
        const { data } = res_data.operationDataList[i];
        let temp_data = {
          aliasName: data.companyVO.aliasName,
          name: data.companyVO.name,
          vat: data.companyVO.vat,
          customerId: data.businessCustomerId,
          logoId: data.companyVO.logo,
          companyType: data.companyVO.companyType,
          companyId: data.companyVO.companyId,
          url: "",
          allConnection: data.userVOList,
          followed: data.followed,
        };
        if (temp_data.logoId && temp_data.logoId.savePath) {
          temp_data.url = temp_data.logoId.savePath;
        } else {
          temp_data.url =
            "https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/img2.png";
        }
        this[key].push(temp_data);
      }
    },
    handleCustomerClose() {
      this.addCustomerShow1 = false;
    },
    handleApproveClose(item) {
      if (item?.companyId) this.getList({ currentPage: 1, pageSize: 5 });
      this.approveListShow1 = false;
    },
    invite(item) {
      this.customerCompanyId = item.companyCustomerId;
      this.allConnection = item.allConnection;
      this.approveListShow1 = true;
    },
    linkTo(path, item) {
      this.$router.push({
        path: path,
        query: {
          companyId: item.companyId,
          companyType: item.companyType
        },
      });
      window.localStorage.setItem("currentPath", "/customerList");
    },
    linkToOrder(path, item) {
      this.$router.push({
        path: path,
        query: {
          orderId: item.orderId,
        },
      });
      window.localStorage.setItem("currentPath", "/order");
    },
    toCustomer() {
      this.$router.push({
        path: "/customerList",
      });
      window.localStorage.setItem("currentPath", "/customerList");
    },
    toOrder() {
      this.$router.push({
        path: "/order",
      });
      window.localStorage.setItem("currentPath", "/order");
    },
    //收藏该用户关系
    setTop(item, index) {
      this.$refs.popover_customer[index].showPopper = false;
      this.loading_customer = true;
      if(item.favorite) {
        unCollectCustomer({ customerId: item.companyId }).then(() => {
          this.reload(['InCustomerListdex']);
          this.getList();
        }).finally(() => this.loading_customer = false);
        return;
      }
      collectCustomer({ customerId: item.companyId }).then(() => {
        this.reload(['CustomerList']);
        this.getList();
      }).finally(() => this.loading_customer = false);
    },
    //隐藏该客户关联关系
    hideConnection(item, index) {
      this.$refs.popover_customer[index].showPopper = false;
      showCustomer({ 
        customerId: item.companyId
      }, item.hidden).then(() => {
        this.reload(["CustomerList"]);
        this.getList();
        this.$message.success(this.$t('memberList.Operation succeeded'));
      });
    },
    //删除该客户关联关系
    removeConnection(item, index) {
      this.$refs.popover_customer[index].showPopper = false;
      this.$confirm(
        this.$t("home.ConfirmRemoveConnection"),
        this.$t("home.Remove"),
        {
          confirmButtonText: this.$t("order.Confirms"),
          cancelButtonText: this.$t("order.Cancel"),
          type: "warning",
        }
      ).then(() => {
        deleteCustomerConnection(item.companyCustomerId).then(() => {
          this.$message.success(this.$t("todo.Delete Successful"));
          this.getList();
          this.reload(['CustomerList']);
        })
      }).catch(() => {});
    },
    //创建新订单
    createOrder() {
      this.action = "create";
      this.editContent = null;
      this.$refs.createOrderDialog.open();
    },
    activePopover(arr, row, key) {
      row.check = !row.check;
      this.$nextTick(() => {
        arr.forEach((item, index) => {
          if((item.orderId || item.companyId) != (row.orderId || row.companyId)) {
            item.check = false;
            this.$refs[key][index].showPopper = false;
          }
        });
      });
    },
    //收藏订单
    followOrder(item, index) {
      this.$refs.popover_order[index].showPopper = false;
      var data_follow = {
        orderId: item.orderId,
      };
      follow(data_follow)
        .then((response) => {
          this.reload(["Order"]);
          item.followed = true;
          this.getOrders();
        })
    },
    //解除收藏订单
    unFollowOrder(item, index) {
      this.$refs.popover_order[index].showPopper = false;
      this.$confirm(
        this.$t("xiaoxie.Confirm unfollow this order"),
        this.$t("home.Unfollow"),
        {
          confirmButtonText: this.$t("xiaoxie.confirm"),
          cancelButtonText: this.$t("xiaoxie.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          var data_unfollow = {
            orderId: item.orderId,
          };
          unfollow(data_unfollow)
            .then(() => {
              this.reload(["Order"]);
              item.followed = false;
              this.getOrders();
            }).catch((error) => {
              this.$message.error("unfollow order error");
            });
        })
        .catch(() => {});
    },
    //更新订单的除人员，状态的以外参数
    editOrder(item) {
      if (this.companyCurrentType == "1") {
        this.action = "edit";
        this.editContent = item;
        this.$refs.createOrderDialog.open();
      }
    },
    //删除订单更新前端界面
    afterDeleteOrder(data) {
      //删除之后再初始化一次桌面订单清单
      this.reload(["Customer", "Order"]);
      this.getOrders();
    },
    //删除订单操作
    deleteOrder(item) {
      this.deleteOrderShow = true;
      this.deleteInfo = item;
    },
    //新增订单更新前端渲染
    afterCreateOrder(data) {
      //创建或更新之后再初始化一次桌面订单清单
      this.reload(["Customer", "Order", "OrderDetails"]);
      this.getOrders();
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
