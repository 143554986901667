<template>
  <div style="height: calc(100% - 425px);">
    <el-card class="message_box" v-loading="loading_news">
      <div class="box_top">
        <div class="title">
          <span :class="`${switchTitle === '0' ? 'active' : ''}`" @click="switchTitle = '0'">
            {{ $t("home.Message") }}
          </span>
          <span :class="`${switchTitle === '3' ? 'active' : ''}`" @click="switchTitle = '3'">
            {{ $t("home.Event") }}
          </span>
          <span :class="`${switchTitle === '1' ? 'active' : ''}`" @click="switchTitle = '1'">
            {{ $t("home.Todo") }}
          </span>
        </div>
        <div class="more" @click="operation('see')">
          <span>
            {{ $t("memberList.more") }}
            <i class="el-icon-arrow-right" />
          </span>
        </div>
      </div>
      <div>
        <ul class="message_box_list" v-if="switchTitle === '0'">
          <li v-for="(item, index) in newsList" :key="index">
            <userItemMessage :item="item" type="message" @click="operation('message', item)" />
            <el-popover
              ref="popover_message"
              class="el-pop"
              placement="bottom"
              trigger="click"
              :offset="-10"
              @hide="item.check = false"
              popper-class="ec-popper"
            >
              <div class="popperMenuItem" @click="setTop(item, index)">
                <i class="el-icon-download rotate180" />
                <span>{{ item.top ? $t('home.Cancel Topping') : $t('home.Chat Top') }}</span>
              </div>
              <i
                class="iconfont icon-Ellipsis downEllipsis"
                :class="item.check ? 'active' : ''"
                slot="reference"
                @click.stop="activePopover(item)"
              />
            </el-popover>
          </li>
          <el-empty 
            :description="$t('overallSituation.noData')" 
            :image="$emptyImage"
            :image-size="90"
            v-if="!newsList.length && switchTitle === '0'" 
          />
        </ul>
      </div>
      <div>
        <ul v-if="switchTitle === '3'" class="comment_box_list">
          <li v-for="(item, index) in [...eventList].splice(0, 5)" :key="index">
            <userItemMessage :item="item" type="event" @click="operation('event', item)" />
          </li>
        </ul>
        <el-empty 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
          v-if="!eventList.length && switchTitle === '3'" 
        />
      </div>
      <div>
        <ul v-if="switchTitle === '1'" class="todo_box_list">
          <li v-for="(item, index) in ToDoList" :key="index">
            <userItemMessage :item="item" type="todo" :businessUserId="businessUserId" @click="operation('todo', item)" />
          </li>
        </ul>
        <el-empty 
          :description="$t('overallSituation.noData')" 
          :image="$emptyImage"
          :image-size="90"
          v-if="!ToDoList.length && switchTitle === '1'" 
        />
      </div>
    </el-card>
    <assignApproveList :approveListShow.sync="approveListShow1" :customerCompanyId="customerCompanyId"
      @handleClose="approveListShow1 = false" :func="'Assign'" />
    <todoInnerDrawer :innerDrawer.sync="innerDrawer" :todoItemId="todoItemId" @handleClose="innerDrawer = false" />
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

import assignApproveList from "@/components/assignApproveList/assignApproveList";
import todoInnerDrawer from "@/components/todoDrawer/todoInnerDrawer";
import userItemMessage from "./components/userItemMessage";

import { getEventList, eventRead } from "@/api/event";
import { 
  messageSessionList, 
  todoItemPage,
  ChatTop
} from "@/api/news";

export default {
  name: "Information",
  components: {
    assignApproveList,
    todoInnerDrawer,
    userItemMessage
  },
  inject: ["reload"],
  data() {
    return {
      newsList: [],
      eventList: [],
      ToDoList: [],
      approveListShow1: false,
      customerCompanyId: "",
      loading_news: false,
      innerDrawer: false,
      todoItemId: null,
      switchTitle: "0",
    };
  },
  computed: {
    ...mapGetters([
      "businessUserId",
      "chatMessage",
      "chatRead",
      "eventUpdate", 
      "readEvent",
      "todoUpdate",
      "readTodo",
      "groupChatUpdate"
    ]),
  },
  watch: {
    groupChatUpdate() {
      this.getMessageSessionList();
    },
    chatMessage() {
      this.getMessageSessionList();
    },
    chatRead() {
      this.getMessageSessionList();
    },
    eventUpdate() {
      this.onGetEventList();
    },
    readEvent() {
      this.onGetEventList();
    },
    todoUpdate() {
      this.onGetTodoListPage();
    },
    readTodo() {
      this.onGetTodoListPage();
    }
  },
  mounted() {
    this.getMessageSessionList();
    this.onGetEventList();
    this.onGetTodoListPage();
  },
  methods: {
    //聊天置顶显示按钮
    activePopover(row) {
      row.check = !row.check;
      this.$nextTick(() => {
        this.newsList.forEach((item, index) => {
          if(item.user.openId != row.user.openId) {
            item.check = false;
            this.$refs.popover_message[index].showPopper = false;
          }
        });
      });
    },
    setTop(item, index) {
      ChatTop(item.user.openId).then(() => {
        this.getMessageSessionList();
        this.$refs.popover_message[index].showPopper = false;
      });
    },
    //信息列表
    getMessageSessionList() {
      this.loading_news = true;
      messageSessionList({
        currentPage: 1,
        pageSize: 5
      }).then((data) => {
        this.newsList = data.list.map(item => ({
          ...item,
          user: item.user || {
            ...item.group,
            openId: item.group.groupId,
            nickName: item.group.groupName,
          },
          check: false,
          content: item.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
        }));
      })
      .finally(() => (this.loading_news = false));
    },
    //获取事件列表
    onGetEventList() {
      this.loading_news = true;
      getEventList({ 
        currentPage: 1, 
        pageSize: 5, 
        excludeRead: false 
      }).then((data) => {
        this.eventList = data.list.map((res) => {
          return {
            ...res,
            user: res.createPerson,
            content: res.keyEvent ? this.$t('order.systemEvent') : res.content.replace(/<?img[^>]*>/gi, `[${this.$t('image')}]`).replaceAll(/\<[\s\S]*?\>/g, ""),
          };
        });
      }).finally(() => (this.loading_news = false));
    },
    //获取To-do列表
    onGetTodoListPage() {
      this.loading_news = true;
      todoItemPage({ 
        currentPage: 1, 
        pageSize: 5,
        finish: false
      }).then((data) => {
        this.ToDoList = data.list.map(res => ({
          ...res,
          user: res.updatePerson,
        }));
      }).finally(() => (this.loading_news = false));
    },
    operation(type, data) {
      switch (type) {
        case "message":
          this.$store.dispatch("user/setMessageDialogOpen", {
            visible: true,
            isElastic: false,
            top: data.top,
            otherParty: data.user
          });
          break;
        case "event":
          eventRead(data.eventId).then(() => {
            this.onGetEventList();
            this.$store.dispatch("user/setReadStatus", "READEVENT");
            this.reload(["OrderDetails"]);
            this.$router.push({
              path: "/order/orderDetails",
              query: {
                orderId: data.orderId,
                eventId: data.eventId
              },
            });
          });
          break;
        case "see":
          this.$router.push({
            path: "/messageList",
            query: { type: this.switchTitle },
          });
          break;
        case "todo":
          this.todoItemId = data.todoItemId;
          this.innerDrawer = true;
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.message_box {
  width: 100%;
  height: 100%;
  .box_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
    margin-bottom: 14px;

    .title {
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        font-size: 14px;
        color: #637381;
        transition: all .3s;
        &:hover {
          color: #67A691;
        }
      }

      span:nth-child(2) {
        margin: 0 18px;
      }

      .active {
        color: #122545;
        transform: scale(1.1) translateY(-0.2px);
        font-weight: 500;
        &:hover {
          color: #122545;
          opacity: .8;
        }
      }
    }

    .more {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 12px;
      color: #2e3f4f;
      font-weight: 500;
      &:hover {
        color: #67A691;
      }
    }
  }

  .message_box_list {
    li {
      position: relative;
      .el-pop {
        position: absolute;
        top: 8px;
        right: 10px;
      }
    }
    .opposite_user {
      display: flex;
      align-items: center;
      margin-top: 24px;
      cursor: pointer;
      position: relative;

      .active {
        position: absolute;
        z-index: 999;
        top: 5px;
        left: 35px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(245, 108, 108, 1);
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      .opposite_user_content {
        width: 100%;
        margin-left: 8px;

        .opposite_user_content_top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 7px;

          span:nth-child(1) {
            width: 120px;
            font-size: 14px;
            font-weight: 500;
            color: rgba(46, 63, 79, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: rgba(46, 63, 79, 0.5);
          }
        }

        .the_message {
          width: 240px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(46, 63, 79, 0.5);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .customer_approvevo {
      display: flex;
      align-items: center;
      margin-top: 24px;
      position: relative;

      .active {
        position: absolute;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(245, 108, 108, 1);
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      .img {
        width: 40px;
        height: 40px;
        margin: 4px;
        margin-left: 7px;
        border-radius: 50%;
      }

      .customer_approvevo_content {
        margin-left: 8px;

        .content_describe {
          width: calc(409px - 8px - 52px);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            color: #226db3;
          }
        }

        .content_operation {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .operation {
            display: flex;
            align-items: center;

            span {
              width: 63px;
              line-height: 21px;
              border-radius: 2px;
              border: 1px solid rgba(46, 63, 79, 1);
              text-align: center;
              font-size: 11.64px;
              font-weight: 500;
              cursor: pointer;

              img {
                width: 10px;
                height: 7px;
              }
            }

            span:nth-child(1) {
              color: rgba(255, 255, 255, 1);
              background: rgba(46, 63, 79, 1);
              margin-right: 8px;
            }

            span:nth-child(2) {
              color: rgba(46, 63, 79, 1);
              background-color: #fff;
            }
          }

          span {
            font-size: 12px;
            font-weight: 400;
            color: rgba(46, 63, 79, 0.5);
          }
        }
      }
    }

    .approv_emember {
      display: flex;
      align-items: center;
      margin-top: 24px;
      position: relative;

      .active {
        position: absolute;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(245, 108, 108, 1);
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      .approv_emember_content {
        margin-left: 8px;

        .approv_emember_describe {
          width: calc(409px - 8px - 52px);
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            color: rgba(34, 109, 179, 1);
          }
        }

        .approv_emember_operation {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .operation {
            display: flex;
            align-items: center;

            span {
              width: 63px;
              line-height: 21px;
              border-radius: 2px;
              border: 1px solid rgba(46, 63, 79, 1);
              text-align: center;
              font-size: 11.64px;
              font-weight: 500;
              cursor: pointer;

              img {
                width: 10px;
                height: 7px;
              }
            }

            span:nth-child(1) {
              color: rgba(255, 255, 255, 1);
              background: rgba(46, 63, 79, 1);
              margin-right: 8px;
            }

            span:nth-child(2) {
              color: rgba(46, 63, 79, 1);
              background-color: #fff;
            }
          }

          span {
            font-size: 12px;
            font-weight: 400;
            color: rgba(46, 63, 79, 0.5);
          }
        }
      }
    }
  }

  .comment_box_list {
    .comment_box_list_item {
      margin-top: 24px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .active {
        position: absolute;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(245, 108, 108, 1);
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      .item_content {
        width: 100%;
        margin-left: 8px;

        .order_number {
          width: calc(409px - 8px - 52px);
          margin-bottom: 7px;
          font-size: 14px;
          font-weight: 500;
          color: rgba(46, 63, 79, 1);

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span {
            color: rgba(34, 109, 179, 1);
          }
        }

        .item_message {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;

          span:nth-child(1) {
            width: 200px;
            color: rgba(46, 63, 79, 1);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span:nth-child(2) {
            color: rgba(179, 179, 179, 1);
          }
        }
      }
    }
  }

  .todo_box_list {
    .todo_box_list_item {
      margin-top: 24px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;

      .active {
        position: absolute;
        top: 50%;
        left: -13px;
        transform: translate(0, -50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: rgba(245, 108, 108, 1);
        border: 1.5px solid rgba(255, 255, 255, 1);
      }

      .item_content {
        width: 100%;
        margin-left: 8px;

        .item_content_prompt {
          width: calc(409px - 8px - 52px);
          font-size: 14px;
          font-weight: 500;
          color: rgba(46, 63, 79, 1);
          margin-bottom: 7px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            color: rgba(34, 109, 179, 1);
          }
        }

        .item_content_time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          color: rgba(46, 63, 79, 1);

          span {
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          span:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: rgba(179, 179, 179, 1);
          }
        }
      }
    }
  }
}
</style>
